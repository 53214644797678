import React from "react"
import _ from "lodash"
import {
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from "react-icons/fa"

const Pagination = props => {
  let delta_pages = 5
  const pagination = props.pagination
  if (pagination.total === 0) return null
  let mod = pagination.current_page % delta_pages
  let pages = []
  let start = 0
  let stop = 0
  if (mod !== 1) {
    if (mod === 0) {
      mod = delta_pages
    }
    start = pagination.current_page - (mod - 1)
    stop = start + delta_pages
    if (stop > pagination.last_page) {
      stop = pagination.last_page
    }
  } else {
    start = pagination.current_page
    stop = start + delta_pages
    if (stop > pagination.last_page) {
      stop = pagination.last_page + 1
    }
  }
  pages = _.range(start, stop)

  function jump(direction) {
    let mod = 0
    let value = 0
    switch (direction) {
      case "down":
        value = pagination.current_page - delta_pages
        mod = value % delta_pages
        if (mod === 0) {
          mod = delta_pages
        }
        if (mod < delta_pages) {
          value += delta_pages - mod
        }
        break
      default:
        value = pagination.current_page + delta_pages
        mod = value % delta_pages
        if (mod === 0) {
          mod = delta_pages
        }
        if (mod > 1) {
          value -= mod - 1
        }
        break
    }
    return value
  }

  function isThisTheEnd() {
    let page = pagination.current_page
    let mod = page % delta_pages
    while (mod > 0) {
      page++
      mod = page % delta_pages
    }
    return page >= pagination.last_page
  }

  return (
    <nav>
      {pagination.last_page > 1 && (
        <ul className="pagination">
          <li
            className={
              pagination.current_page === 1 ? "page-item disabled" : "page-item"
            }
          >
            <span
              className="page-link pagination-button c-pointer"
              tabIndex="-1"
              onClick={() => props.onPageChange(1)}
            >
              <FaAngleDoubleLeft size={8} />
            </span>
          </li>
          <li
            className={
              pagination.current_page === 1 ? "page-item disabled" : "page-item"
            }
          >
            <span
              className="page-link pagination-button c-pointer"
              tabIndex="-1"
              onClick={() => props.onPageChange(pagination.current_page - 1)}
            >
              <FaAngleLeft size={8} />
            </span>
          </li>
          {pagination.current_page > delta_pages && (
            <li className="page-item">
              <span
                className="page-link pagination-button c-pointer"
                tabIndex="-1"
                onClick={() => props.onPageChange(jump("down"))}
              >
                ...
              </span>
            </li>
          )}

          {pages.map(page => (
            <li
              key={page}
              className={
                page === pagination.current_page
                  ? "page-item active"
                  : "page-item"
              }
            >
              <span
                className="page-link pagination-button c-pointer"
                onClick={() => props.onPageChange(page)}
              >
                {page}
              </span>
            </li>
          ))}

          {!isThisTheEnd() && (
            <li className="page-item">
              <span
                className="page-link pagination-button c-pointer"
                tabIndex="-1"
                onClick={() => props.onPageChange(jump("up"))}
              >
                ...
              </span>
            </li>
          )}

          <li
            className={
              pagination.current_page === pagination.last_page
                ? "page-item disabled"
                : "page-item"
            }
          >
            <span
              className="page-link pagination-button c-pointer"
              tabIndex="-1"
              onClick={() => props.onPageChange(pagination.current_page + 1)}
            >
              <FaAngleRight size={8} />
            </span>
          </li>
          <li
            className={
              pagination.current_page === pagination.last_page
                ? "page-item disabled"
                : "page-item"
            }
          >
            <span
              className="page-link pagination-button c-pointer"
              tabIndex="-1"
              onClick={() => props.onPageChange(pagination.last_page)}
            >
              <FaAngleDoubleRight size={8} />
            </span>
          </li>
        </ul>
      )}
    </nav>
  )
}

export default Pagination
